import { Action, createReducer, on } from '@ngrx/store';
import { EventLimitsFailure, SbGetEventLimits, SbSetBetTypesForProduct, SbSetEventLimits, UpdateEventLimitSetIncreaseSuccess } from './event-limit.actions';
import { eventLimitAdapter, eventLimitInitialState, EventLimitsState } from './event-limit.state';

export const EventLimitReducer = createReducer(
  eventLimitInitialState,
  on(SbGetEventLimits, state => ({ ...state })),
  on(SbSetEventLimits, (state, { payload }) => {
    payload.items.forEach(it => {
      it.setIncreaseFor = it.exposureLeftSingle >= it.exposureLeftCombo ? 'Single' : 'Combo';
    });
    return eventLimitAdapter.setAll(payload.items, {
      ...state,
      totalCount: payload.totalCount
    });
  }),
  on(SbSetBetTypesForProduct, (state, { betTypes }) => ({
    ...state,
    betTypeOptions: betTypes
  })),
  on(EventLimitsFailure, (state, payload) => {
    return {
      ...state,
      operationResult: {
        id: payload.identifier,
        isSuccessful: false,
        operationType: payload.operationType
      }
    };
  }),
  on(UpdateEventLimitSetIncreaseSuccess, (state, { eventId, betTypeId, selection, newSetIncrease }) => {
    const compositeKey = `${eventId}-${betTypeId}-${selection}`;
    return eventLimitAdapter.updateOne({
      id: compositeKey,
      changes: { setIncrease: newSetIncrease },
    }, state);
  })
);

export function reducer(state: EventLimitsState | undefined, action: Action): EventLimitsState {
  return EventLimitReducer(state, action);
}

import { isNullOrUndefined } from 'abs@shared/shared-functions/functions';
import * as moment from 'moment';
import { BaseUser } from 'abs@core/models/coreModels';
export interface OrderData {
  settledBy: SettledByAutomaticModel;
  winStatusId: number;
  orderId: number;
  prepaidCode: string;
  requestGuid: string;
  type: string;
  product: BaseNameId;
  manualChangedByUser: SpecialUserModel;
  productData: Line[];
  orderNumber: number;
  externalId: number;
  transactionId: number;
  createTime: string;
  cancelTime: string;
  expiryDate: string;
  soldTime: string;
  settledTime: string;
  lockedUser: BaseUser;
  lockedReason: string;
  lockedTime: string;
  checkoutTime: string;
  branch: BaseNameId;
  user: SpecialUserModel;
  terminal: BaseNameId;
  currency: Currency;
  authorization: Authorization;
  checkedoutBranch: BaseNameId;
  CheckedoutKiosk: BaseNameId;
  checkedoutUser: SpecialUserModel;
  barcode: number;
  amount: number;
  maxPayout: number;
  maxWinning: number;
  status: number;
  actualPayout: number;
  FreeBetBonusAmount: number;
  printCount: number;
  totalBonus: number;
  totalTax: number;
  bonusStake: number;
  netPayout: number;
  totalCommission: number;
  totalSelections: number;
  salesCommission: number;
  payoutCommission: number;
  totalLines: number;
  totalStake: number;
  bookingCode: string;
  pendingRatio: number;
  nextBetBonus: number;
  nextBetBonusId: number;
  previousNextBetBonus: number;
  previousNextBetBonusId: number;
  canceledUser: SpecialUserModel;
  expairedDateUser: SpecialUserModel;
  cancelReasonType: number;
  cancellationReason: string;
  allowReprint: boolean;
  extendExpairedDateUser: extendedExpirationUser;
  hasExciseTax: boolean;
  printUrlKey: string;
}

export interface SettledByAutomaticModel {
  id: number;
  name: string;
  userName: string;
}
export interface SpecialUserModel {
  id: number;
  name: string;
  userName: string;
}
export interface extendedExpirationUser {
  id: number;
  name: string;
  userName: string;
  originalExpireDate: string;
}
export interface Line {
  gameType?: number;
  rno?: any;
  mno?: any;
  bets: Bet[];
  gameName: string;
  id: number; //shared
  winStatusId: number; // shared
  cashoutFee: number;
  totalOdds: number;
  stake: number;
  tax: number;
  bonus: Bonus;
  maxWinning: number;
  probabilityToWin: number;
  payout: number;
  commission: number; // shared
  nextBetBonus: number; // shared
  nextBetBonusId: number; // shared
  campaign: BaseNameId; //shared
  type: string; // shared
  market: BaseNameId;
  manualChangedByUser: BaseNameId;
  totalStake: number;
  statusId: number;
  oddStatusId: number;
  orderBetId: number;
  orderRowId: number;
  selection: string;
  date: string;
  provider: BaseNameId;
  category: BaseNameId;
  game: BaseNameId;

  //IQSOFT
  bet: string;
  drawDate: string; // shared
  drawId: number; // shared
  drawResults: string;

  //lotto
  lines: LottoLine[];
  winningNumbers: string;
  winningNumbersClient: any;
  winningBonusClient: any;
  lottoBetStatus: number;
  betTypeId: number;
  generateTime: string;
  jackpot: number;
  winningAmount: number;
  totalwinningAmount: number;
  includeBonus: boolean;
}
export interface LottoLine {
  sts?: any;
  gid?: number;
  chosenNumbers: string;
  chosenNumbersClient: any;
  chosenBonusNumbersClient: any;
  hitNumber: number;
  hitBonus: number;
  winStatusId: number;
}
export interface NextBetBonus {
  amount: number;
  orderId: number;
}
export interface Bet {
  id: number;
  date: string;
  live: boolean;
  eventId: number;
  homeTeam: BaseNameId;
  awayTeam: BaseNameId;
  league: BaseNameId;
  sport: BaseNameId;
  score: string;
  market: BaseNameId;
  selection: string;
  odd: number | any;
  maxOdd: number;
  originalOdd: number;
  winStatusId: number;
  originalWinStatusId: number;
  manualUpdateTime: string;
  manualChangedByUser: SpecialUserModel;
}
export interface Betslip {
  orderId: number;
  barcode: string;
  externalId: number;
  product: Product;
  currency: BaseNameId;
  createTime: string;
  payoutTime: string;
  settleTime: string;
  branch: Branch;
  paidBranch: Branch;
  userId: number;
  user: BaseUser;
  stake: number;
  initialStake: number;
  totalStake: number;
  maxPayout: number;
  netPayout: number;
  payout: number;
  printUrl: string;
  lines: number;
  pendingSelection: number;
  selection: number;
  probabilityToWin: number;
  winStatusId: number;
  statusId: number;
  commission: number;
  totalBonus: number;
}
export class OrderExcel {
  public Id: number;
  public Barcode: string;
  public ExternalID: number;
  public Stake: number;
  public MaxPayout: number;
  public NetPayout: number;
  public Payout: number;
  public Lines: number;
  public PendingSelections: number;
  public Selections: number;
  public ProbabilityToWin: string;
  public Commission: number;
  public TotalBonus: number;
  public Product: string;
  public Branch: string;
  public PaidBranch: string;
  public Currency: string;
  public CreateTime: string;
  public SettleTime: string;
  public PayoutTime: string;
  public Name: string;
  constructor(order: Betslip) {
    this.Id = order.orderId;
    this.Barcode = order.barcode;
    this.ExternalID = order.externalId;
    this.Name = isNullOrUndefined(order.user) ? null : order.user.name;
    this.Branch = isNullOrUndefined(order.branch) ? null : order.branch.name;
    this.PaidBranch = isNullOrUndefined(order.paidBranch) ? null : order.paidBranch.name;
    this.Product = isNullOrUndefined(order.product) ? null : order.product.name;
    this.Stake = order.stake;
    this.MaxPayout = order.maxPayout;
    this.NetPayout = order.netPayout;
    this.Payout = order.payout;
    this.Lines = order.lines;
    this.TotalBonus = order.totalBonus;
    this.CreateTime = order.createTime ? moment(order.createTime).format('DD/MM/YYYY HH:mm:ss') : null;
    this.PayoutTime = order.payoutTime ? moment(order.payoutTime).format('DD/MM/YYYY HH:mm:ss') : null;
    this.SettleTime = order.settleTime ? moment(order.settleTime).format('DD/MM/YYYY HH:mm:ss') : null;
    this.Currency = isNullOrUndefined(order.currency) ? null : order.currency.name;
    this.Selections = order.selection;
    this.PendingSelections = order.pendingSelection;
    this.ProbabilityToWin = order.probabilityToWin + '%';
  }
}
export interface Product {
  id: number;
  name: string;
}
export interface Currency {
  id: number;
  code: string;
  description: string;
  minFactor: number;
}
export interface Branch {
  id: number;
  name: string;
}
export interface BaseNameId {
  id: number;
  name: string;
  provider?: string;
}
export class Totals {
  constructor(
    public totalStake: number = 0,
    public totalInitialStake: number = 0,
    public totalPayout: number = 0,
    public totalMaxPayout: number = 0,
    public totalNetPayout: number = 0,
    public totalCommission: number = 0,
    public totalBonus: number = 0
  ) { }
}
export interface Authorization {
  id: number;
  soldOrderId: string;
  createDate: string;
  authorizationDate: string;
  authorizationStatus: number;
  authorizedBy: number;
  authorizationValidTo: string;
  authorizedByFirstName: string;
  authorizedByLastName: string;
  userName: string;
  userId: number;
}
export interface User {
  id: number;
  userName: string;
  userRole: string;
}

export interface Bonus {
  name: string;
  amount: number;
  typeName: string;
}

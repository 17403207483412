import { Component } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppComponent } from '../app.component';
import { Store } from '@ngrx/store';
import { getInitials, getUserData } from '../auth/store/reducers/auth';
import { getSelectedTimeZone, getTimeZonesDropDown } from '../core/store/reducers/metadata.reducer';
import { tap } from 'rxjs/operators';
import { SelectTimeZone } from '../core/store/actions/metadata.actions';
import { AppSettings, PermissionsScreensCodes } from '../core/app.config';
import { AuthorizationService } from '../shared/authorization/authorization.service';
import { Observable } from 'rxjs';
import { UserData } from '../auth/models/user';

@Component({
  selector: 'app-topbar',
  template: `
    <div class="layout-topbar">
      <div class="layout-topbar-left">
        <a
          href="#"
          class="topbar-menu-button"
          (click)="appMain.onMenuButtonClick($event)"
          *ngIf="appMain.isOverlay() || appMain.isMobile()">
          <i class="pi pi-bars"></i>
        </a>

        <a [routerLink]="'/home'" class="logo">
          <img [src]="'assets/layout/images/logo-' + (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'"
               onerror="this.src='assets/layout/images/login/icon_login_default.png';"/>
        </a>

        <a [routerLink]="'/home'">
          <img
            [src]="'assets/layout/images/appname-' + (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'"
            onerror="this.style.display='none'"
            class="app-name" />
        </a>
      </div>

      <app-menu></app-menu>

      <div class="layout-topbar-right">
        <ul class="layout-topbar-right-items">
          <li #profile class="profile-item" [ngClass]="{ 'active-topmenuitem': appMain.activeTopbarItem === profile }">
            <a href="#" (click)="appMain.onTopbarItemClick($event, profile)">
              <img src="assets/layout/images/profile-image.png" />
            </a>

            <ul class="fadeInDown">
              <li role="menuitem">
                <a href="#" (click)="appMain.onTopbarSubItemClick($event, 'profile')">
                  <i class="pi pi-fw pi-user"></i>
                  <span>{{ (userData | async).userName }} {{ (userData | async).userId }}</span>
                  <!--<span>{{ (userData | async).userName }} {{ (userData | async).userId }}</span>-->
                </a>
              </li>
              <li role="menuitem">
                <a (click)="appMain.onTopbarSubItemClick($event, 'syncUserSettings')">
                  <i class="fas fa-sync"></i>
                  <span>{{ 'Refresh_User_Settings' | translate }}</span>
                </a>
              </li>
              <li class="layout-inline-menu-action-item" *ngIf="checkPermission(screenPermissions.PURGE_CLOUDFLARE_CACHE)">
                <a class="flex flex-row align-items-center" (click)="appMain.onTopbarSubItemClick($event, 'purgeCloudflareCache')">
                  <i class="fa-solid fa-cloud-arrow-up"></i>
                  <span>{{'Purge_Cloudflare_Cache' | translate}}</span>
                </a>
              </li>
              <li role="menuitem">
                <a href="#" (click)="appMain.onTopbarSubItemClick($event, 'clearCache')">
                  <i class="fas fa-eraser"></i>
                  <span>{{ 'Clear_Cache' | translate }}</span>
                  <!--<span [ngStyle]="userDataStyle">{{(userData | async).firstName + ' ' + (userData | async).lastName}}-->
                  <!--</span>-->
                </a>
              </li>
              <li role="menuitem">
                <a (click)="openTimeZoneDialog()">
                  <i class="fas fa-user-clock"></i>
                  <span>{{ selectedTimeZone?.label }}</span>
                </a>
              </li>
              <li role="menuitem">
                <a href="#" (click)="appMain.onTopbarSubItemClick($event, 'logout')">
                  <i class="pi pi-fw pi-sign-out"></i>
                  <span>{{ 'Logout' | translate }}</span>
                </a>
              </li>
            </ul>
          </li>
          <!--<li>
            <a href="#">
              <i class="topbar-icon pi pi-fw pi-bell"></i>
              <span class="topbar-badge">2</span>
              <span class="topbar-item-name">Notifications</span>
            </a>
          </li>
          <li>
            <a href="#">
              <i class="topbar-icon pi pi-fw pi-comment"></i>
              <span class="topbar-badge">5</span>
              <span class="topbar-item-name">Messages</span>
            </a>
          </li>-->
        </ul>
      </div>
    </div>
  `,
})
export class AppTopBarComponent {
  logo = AppSettings.BRAND_DOMAIN;
  selectedTimeZone: any;
  // timeZonesList: SelectItem[];
  timeZonesList: any;
  timeZoneDialogVisible = false;
  initials: any;
  public userData: Observable<UserData>;
  dashboardPermission: boolean;
  screenPermissions = PermissionsScreensCodes;
  userDataStyle = {
    float: 'right',
  };
  initialsStyle = {
    'background-color': '#ececec',
    'font-size': '25px',
    'font-weight': 'bolder',
    'padding-top': '2px',
  };

  constructor(
    private authorizationService: AuthorizationService,
    public appMain: AppMainComponent,
    public app: AppComponent,
    private store: Store<any>
  ) {
    this.initials = this.store.select(getInitials);
    this.userData = this.store.select(getUserData);
    this.timeZonesList = this.store.select(getTimeZonesDropDown);
    this.store
      .select(getSelectedTimeZone)
      .pipe(
        tap((selectedTimeZone) => {
          this.selectedTimeZone = selectedTimeZone;
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.dashboardPermission = this.authorizationService.hasPermission(PermissionsScreensCodes.DASHBOARD_MAIN_SCREEN);
  }

  onChangeTImeZone(event) {
    this.timeZoneDialogVisible = false;
    this.store.dispatch(new SelectTimeZone(event.value.tzCode));
  }

  openTimeZoneDialog() {
    this.timeZoneDialogVisible = true;
  }

  checkPermission(permission): boolean {
    return this.authorizationService.hasPermission(permission);
  }
}

import { createAction, props } from "@ngrx/store";
import { FailAction } from "src/app/sport-betting/common/models/fail-action.model";
import { EventLimit } from '../model/event-limit.model';
import { EventLimitFiltering } from '../model/event-limit-filtering.model';
import { DropdownItem } from "src/app/sport-betting/common/models/dropdown-item.model";
import { UpdateLimit } from "../model";

const GET_EVENT_LIMITS = '[Event Limits] Get Event Limits';
const SET_EVENT_LIMITS = '[Event Limits] Set Event Limits';
const EVENT_LIMITS_FAILURE = '[Event Limits] Operation Failure';

const GET_BET_TYPES_FOR_PRODUCT = '[Event Limits] Get Bet Types for Product';
const SET_BET_TYPES_FOR_PRODUCT = '[Event Limits] Set Bet Types for Product';

const REQUEST_SET_INCREASE_UPDATE = '[Event Limits] Request Set Increase Update';
const UPDATE_SET_INCREASE_SUCCESS = '[Event Limits] Update Set Increase Success';

const SET_EVENT_LIMIT = '[Event Limits] Set Event Limit';
const SET_EVENT_LIMIT_SUCCESS = '[Event Limits] Set Event Limit Success';

export const SbGetEventLimits = createAction(GET_EVENT_LIMITS, props<{ payload: EventLimitFiltering }>());
export const SbSetEventLimits = createAction(SET_EVENT_LIMITS, props<{
  payload: {
    items: Array<EventLimit>,
    totalCount: number;
  }
}>());
export const EventLimitsFailure = createAction(EVENT_LIMITS_FAILURE, props<FailAction>());

export const SbGetBetTypesForProduct = createAction(GET_BET_TYPES_FOR_PRODUCT, props<{ productId: number }>());
export const SbSetBetTypesForProduct = createAction(SET_BET_TYPES_FOR_PRODUCT, props<{ betTypes: Array<DropdownItem<number, string>> }>());

export const UpdateEventLimitSetIncrease = createAction(REQUEST_SET_INCREASE_UPDATE, props<{
  eventId: number; betTypeId: number; selection: string; baseLimit: number; currentLimit: number
}>());
export const UpdateEventLimitSetIncreaseSuccess = createAction(UPDATE_SET_INCREASE_SUCCESS, props<{
  eventId: number; betTypeId: number; selection: string; newSetIncrease: number
}>());

export const SetEventLimit = createAction(SET_EVENT_LIMIT, props<{ payload: UpdateLimit }>());
export const SetEventLimitSuccess = createAction(SET_EVENT_LIMIT_SUCCESS);

import { createReducer, on } from '@ngrx/store';
import { eventBetTypesAdapter, eventBetTypesInitialState } from '../adapters';
import { SbRevertBetTypeManualProperty, SbRevertBetTypeManualPropertyResulted, SbRevertBetTypeOddManualProperty, SbRevertBetTypeOddManualPropertyResulted, SbSetEventBetTypes, SbUpdateBetTypeManualProperty, SbUpdateBetTypeManualPropertyResulted, SbUpdateBetTypeOddManualProperty, SbUpdateBetTypeOddManualPropertyResulted } from '../actions';
import { RevertEventBetTypeManualField, RevertEventBetTypeOddManualField, UpdateEventBetTypeManualField, UpdateEventBetTypeOddManualField } from '../../models';

function getBetTypeManualChangeOperationName(data: UpdateEventBetTypeManualField): string {
  let operatinName;
  if (data.margin) {
    operatinName = 'margin';
  }
  if (data.status) {
    operatinName = 'status';
  }
  if (data.limitSingle) {
    operatinName = 'limitSingle';
  }
  if (data.limitCombo) {
    operatinName = 'limitCombo';
  }
  return operatinName;
}

function getBetTypeRevertManualChangeOperationName(data: RevertEventBetTypeManualField): string {
  let operatinName;
  if (data.field === 'margin') {
    operatinName = 'margin';
  }
  if (data.field === 'status') {
    operatinName = 'status';
  }
  if (data.field === 'limitSingle') {
    operatinName = 'limitSingle';
  }
  if (data.field === 'limitCombo') {
    operatinName = 'limitCombo';
  }
  return operatinName;
}

function getBetTypeOddManualChangeOperationName(data: UpdateEventBetTypeOddManualField): string {
  let operatinName;
  if (data.odds) {
    operatinName = 'odds';
  }
  if (data.status) {
    operatinName = 'status';
  }
  if (data.pips) {
    operatinName = 'pips';
  }
  if (data.winStatus) {
    operatinName = 'winStatus';
  }
  if (data.isBoosted) {
    operatinName = 'isBoosted';
  }
  return operatinName;
}

function getBetTypeOddRevertManualChangeOperationName(data: RevertEventBetTypeOddManualField): string {
  let operatinName;
  if (data.field === 'odds') {
    operatinName = 'odds';
  }
  if (data.field === 'status') {
    operatinName = 'status';
  }
  if (data.field === 'winStatus') {
    operatinName = 'winStatus';
  }
  if (data.field === 'pips') {
    operatinName = 'pips';
  }
  if (data.field === 'isBoosted') {
    operatinName = 'isBoosted';
  }
  return operatinName;
}

function getOddKey(data: UpdateEventBetTypeOddManualField): string {
  return `${data.name}${data.line ?? ''}`;
}

export const EventBetTypeReducer = createReducer(
  eventBetTypesInitialState,
  on(SbSetEventBetTypes, (state, { payload }) => {
    return eventBetTypesAdapter.setAll(payload.items, {
      ...state,
      totalCount: payload.totalCount
    });
  }),
  on(SbUpdateBetTypeManualProperty, (state, { payload }) => {
    const operatinName = `${payload.betTypeId}-${getBetTypeManualChangeOperationName(payload)}`;
    const operationResults = { ...state.operationResult };
    delete operationResults[operatinName];

    return {
      ...state,
      operationResult: operationResults
    };
  }),
  on(SbRevertBetTypeManualProperty, (state, { payload }) => {
    const operatinName = `${payload.betTypeId}-${getBetTypeRevertManualChangeOperationName(payload)}`;
    const operationResults = { ...state.operationResult };
    delete operationResults[operatinName];

    return {
      ...state,
      operationResult: operationResults
    };
  }),
  on(SbUpdateBetTypeManualPropertyResulted, (state, { payload }) => {
    const operatinName = `${payload.request.betTypeId}-${getBetTypeManualChangeOperationName(payload.request)}`;

    return eventBetTypesAdapter.updateOne({
      id: payload.response.id,
      changes: payload.response
    }, {
      ...state,
      operationResult: {
        ...state.operationResult,
        [operatinName]: {
          isSuccessful: payload.isSuccessful
        }
      }
    });
  }),
  on(SbRevertBetTypeManualPropertyResulted, (state, { payload }) => {
    const operatinName = `${payload.request.betTypeId}-${getBetTypeRevertManualChangeOperationName(payload.request)}`;

    return eventBetTypesAdapter.updateOne({
      id: payload.response.id,
      changes: payload.response
    }, {
      ...state,
      operationResult: {
        ...state.operationResult,
        [operatinName]: {
          isSuccessful: payload.isSuccessful
        }
      }
    });
  }),

  on(SbUpdateBetTypeOddManualProperty, (state, { payload }) => {
    const operatinName = `${getOddKey(payload)}-${getBetTypeOddManualChangeOperationName(payload)}`;
    const operationResults = { ...state.oddsOperationResult };
    delete operationResults[operatinName];
    return {
      ...state,
      oddsOperationResult: null
    };
  }),

  on(SbRevertBetTypeOddManualProperty, (state, { payload }) => {
    const operatinName = `${getOddKey(payload)}-${getBetTypeOddRevertManualChangeOperationName(payload)}`;
    const operationResults = { ...state.oddsOperationResult };
    delete operationResults[operatinName];
    return {
      ...state,
      oddsOperationResult: operationResults
    };
  }),
  on(SbUpdateBetTypeOddManualPropertyResulted, (state, { payload }) => {
    const operatinName = `${getOddKey(payload.request)}-${getBetTypeOddManualChangeOperationName(payload.request)}`;
    return eventBetTypesAdapter.updateOne({
      id: payload.response.id,
      changes: payload.response
    }, {
      ...state,
      oddsOperationResult: {
        ...state.oddsOperationResult,
        [operatinName]: {
          isSuccessful: payload.isSuccessful
        }
      }
    });
  }),
  on(SbRevertBetTypeOddManualPropertyResulted, (state, { payload }) => {
    const operatinName = `${payload.request.name + payload.request.line}-${getBetTypeOddRevertManualChangeOperationName(payload.request)}`;
    return eventBetTypesAdapter.updateOne({
      id: payload.response.id,
      changes: payload.response
    }, {
      ...state,
      oddsOperationResult: {
        ...state.oddsOperationResult,
        [operatinName]: {
          isSuccessful: payload.isSuccessful
        }
      }
    });
  }),
);

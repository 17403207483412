import { Action } from '@ngrx/store';
import { PaymentMethod } from '../../models/payment-methods.model';

/******************PAYMENT_METHODS_MANAGEMENT************************/

export const GET_PAYMENT_METHODS = '[PAYMENT_METHODS] Get Payment Methods';
export const CLEAR_PAYMENT_METHODS = '[PAYMENT_METHODS] Clear Payment Methods';
export const EDIT_PAYMENT_METHOD = '[PAYMENT_METHODS] Edit Payment Methods';
export const CANCEL_EDIT_PAYMENT_METHOD = '[PAYMENT_METHODS] Cancel Edit Payment Methods';
export const SAVE_PAYMENT_METHOD = '[PAYMENT_METHODS] Save Payment Method';
export const SAVE_PAYMENT_METHOD_SUCCESS = '[PAYMENT_METHODS] Save Payment Method Success';

export const SET_PAYMENT_METHODS = '[PAYMENT_METHODS] Set Payment Methods';
export const SET_PAYMENT_METHOD = '[PAYMENT_METHOD] Set Payment Method';

export const SET_FAILURE = '[PAYMENT_METHODS] Set Failure';
export const FAILURE = '[PAYMENT_METHODS] Failure';


export class GetPaymentMethods implements Action {
  readonly type = GET_PAYMENT_METHODS;
  constructor(public payload: any = null) {}
}

export class SetPaymentMethods implements Action {
  readonly type = SET_PAYMENT_METHODS;
  constructor(public payload: PaymentMethod[] = null) {}
}

export class ClearPaymentMethods implements Action {
  readonly type = CLEAR_PAYMENT_METHODS;
  constructor(public payload: any = null) {}
}

export class EditPaymentMethod implements Action {
  readonly type = EDIT_PAYMENT_METHOD;
  constructor(public payload = null) {}
}

export class CancelEditPaymentMethod implements Action {
  readonly type = CANCEL_EDIT_PAYMENT_METHOD;
  constructor(public payload = null) {}
}

export class SavePaymentMethod implements Action {
  readonly type = SAVE_PAYMENT_METHOD;
  constructor(public payload: any = null) {}
}

export class SavePaymentMethodSuccess implements Action {
  readonly type = SAVE_PAYMENT_METHOD_SUCCESS;
  constructor(public payload: PaymentMethod = null) {}
}

export class SetPaymentMethod implements Action {
  readonly type = SET_PAYMENT_METHOD;
  constructor(public payload: PaymentMethod = null) {}
}

export class Failure implements Action {
  readonly type = FAILURE;
  constructor(public payload: any) {}
}

export class SetFailure implements Action {
  readonly type = SET_FAILURE;
}

export type Actions =
  | GetPaymentMethods
  | SetPaymentMethods
  | ClearPaymentMethods
  | EditPaymentMethod
  | CancelEditPaymentMethod
  | SavePaymentMethod
  | SavePaymentMethodSuccess
  | SetPaymentMethod
  | Failure
  | SetFailure;

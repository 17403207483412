import { createReducer, on } from '@ngrx/store';
import { SbChangeWatchDogSuccess, SbRevertEventManualProperty, SbRevertEventManualPropertyResulted, SbSetEvent, SbSetEventSettlementSuccess, SbUpdateEventManualProperty, SbUpdateEventManualPropertyResulted } from '../actions';
import { eventDetailAdapter, eventDetailInitialState } from '../adapters';
import { RevertEventManualField, UpdateEventManualField } from '../../models';
import { isNotNullOrUndefined } from 'src/app/sport-betting/common/utilities';

function getManualChangeOperationName(data: UpdateEventManualField): string {
  let operatinName;
  if (data.eventDate) {
    operatinName = 'eventDate';
  }
  if (data.eventStatus) {
    operatinName = 'eventStatus';
  }
  if (isNotNullOrUndefined(data.isPinned)) {
    operatinName = 'isPinned';
  }
  if (data.prematchLimitSingle) {
    operatinName = 'prematchLimitSingle';
  }
  if (data.prematchLimitCombo) {
    operatinName = 'prematchLimitCombo';
  }
  if (data.liveLimitSingle) {
    operatinName = 'liveLimitSingle';
  }
  if (data.liveLimitCombo) {
    operatinName = 'liveLimitCombo';
  }
  return operatinName;
}

function getRevertManualChangeOperationName(data: RevertEventManualField): string {
  let operatinName;
  if (data.field === 'eventDate') {
    operatinName = 'eventDate';
  }
  if (data.field === 'eventStatus') {
    operatinName = 'eventStatus';
  }
  if (data.field === 'all') {
    operatinName = 'all';
  }
  return operatinName;
}

export const EventDetailReducer = createReducer(
  eventDetailInitialState,
  on(SbSetEvent, (state, { payload }) => {
    return eventDetailAdapter.setOne(payload, state);
  }),
  on(SbUpdateEventManualProperty, (state, { payload }) => {
    const operatinName = getManualChangeOperationName(payload);
    const operationResults = { ...state.operationResult };
    delete operationResults[operatinName];

    return {
      ...state,
      operationResult: operationResults
    };
  }),
  on(SbRevertEventManualProperty, (state, { payload }) => {
    const operatinName = getRevertManualChangeOperationName(payload);
    const operationResults = { ...state.operationResult };
    delete operationResults[operatinName];

    return {
      ...state,
      operationResult: operationResults
    };
  }),
  on(SbUpdateEventManualPropertyResulted, (state, { payload }) => {
    const operatinName = getManualChangeOperationName(payload.request);

    return eventDetailAdapter.updateOne({
      id: payload.response.eventId,
      changes: payload.response
    }, {
      ...state,
      operationResult: {
        ...state.operationResult,
        [operatinName]: {
          isSuccessful: payload.isSuccessful
        }
      }
    });
  }),
  on(SbSetEventSettlementSuccess, (state) => {
    const operatinName = 'all';

    return {
      ...state,
      operationResult: {
        ...state.operationResult,
        [operatinName]: {
          isSuccessful: true
        }
      }
    };
  }),

  on(SbRevertEventManualPropertyResulted, (state, { payload }) => {
    const operatinName = getRevertManualChangeOperationName(payload.request);

    return eventDetailAdapter.updateOne({
      id: payload.response.eventId,
      changes: payload.response
    }, {
      ...state,
      operationResult: {
        ...state.operationResult,
        [operatinName]: {
          isSuccessful: payload.isSuccessful
        }
      }
    });
  }),

  on(SbChangeWatchDogSuccess, (state) => {
    const operatinName = 'all';

    return {
      ...state,
      operationResult: {
        ...state.operationResult,
        [operatinName]: {
          isSuccessful: true
        }
      }
    };
  }),
);
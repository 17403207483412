import * as paymentMethodsManagement from '../actions/payment-methods-management.actions';
import {AppState} from 'abs@core/store/reducers';
import copy from 'fast-copy';
import { PaymentMethod } from '../../models/payment-methods.model';

export interface State {
  paymentsLoaded: boolean;
  paymentMethods: PaymentMethod[];
  clonedPaymentMethods: PaymentMethod[];
  isLoadingPayments: boolean;
  isPaymentSavedSuccess: boolean;
}

export const initialState: State = {
  paymentsLoaded: false,
  paymentMethods: null,
  clonedPaymentMethods: null,
  isLoadingPayments: false,
  isPaymentSavedSuccess: false
};

export function PaymentMethodsManagementReducer(state = initialState, action: paymentMethodsManagement.Actions): State {
  switch (action.type) {
  case paymentMethodsManagement.GET_PAYMENT_METHODS: {
    return {
      ...state,
      isLoadingPayments: true,
    };
  }
  case paymentMethodsManagement.SET_PAYMENT_METHODS: {
    return {
      ...state,
      paymentsLoaded: action.payload != null && action.payload.length > 0,
      paymentMethods: action.payload,
      clonedPaymentMethods: action.payload,
      isLoadingPayments: false,
    };
  }
  case paymentMethodsManagement.CLEAR_PAYMENT_METHODS: {
    return {
      ...state,
      paymentsLoaded: false,
      paymentMethods: null,
      isLoadingPayments: true,
    };
  }
  case paymentMethodsManagement.EDIT_PAYMENT_METHOD: {
    return {
      ...state,
      paymentMethods: state.paymentMethods.map((item) =>
        item.id === action.payload.payment.id
          ? { ...action.payload.payment, isEditable: true }
          : { ...state.clonedPaymentMethods.find(clone => clone.id === item.id) || item, isEditable: false }
      )
    };
  }
  case paymentMethodsManagement.CANCEL_EDIT_PAYMENT_METHOD: {
    return {
      ...state,
      paymentMethods: state.paymentMethods.map((item) => {
        if (item.id === action.payload.payment.id) {
          const clonedIndex = state.clonedPaymentMethods.findIndex(
            (clonedItem) => clonedItem.id === action.payload.payment.id
          );

          return clonedIndex !== -1
            ? { ...state.clonedPaymentMethods[clonedIndex] }
            : item;
        }
        return item;
      })

    };
  }
  case paymentMethodsManagement.SAVE_PAYMENT_METHOD: {
    return {
      ...state,
      isLoadingPayments: true,
      paymentMethods: state.paymentMethods.map((item) => item.id === action.payload.payment.id ? action.payload.payment : item),
    };
  }
  case paymentMethodsManagement.SAVE_PAYMENT_METHOD_SUCCESS: {
    return {
      ...state,
      paymentMethods: state.paymentMethods.map(item => (item.id === action.payload.id ? action.payload : item)),
      clonedPaymentMethods: state.clonedPaymentMethods.map(item => (item.id === action.payload.id ? action.payload : item)),
      isLoadingPayments: false,
      isPaymentSavedSuccess: true
    };
  }
  case paymentMethodsManagement.FAILURE: {
    return {
      ...state,
      isLoadingPayments: false,
    };
  }
  default: {
    return state;
  }
  }
}
export const arePaymentMethodsLoading = (state: AppState): boolean | null => (state.paymentMethodsManagementReducer ? state.paymentMethodsManagementReducer.isLoadingPayments : false);
export const paymentMethodsLoaded = (state: AppState): boolean | null => (state.paymentMethodsManagementReducer ? state.paymentMethodsManagementReducer.paymentsLoaded : false);
export const getPaymentMethodsManagement = (state: AppState): any => {return copy(state.paymentMethodsManagementReducer.paymentMethods);};

